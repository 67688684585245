import React from "react";
import Title from "../../components/Title";
import InputTextAttribute from "../../components/InputTextAttribute";
import { connect } from "react-redux";
// import './Logistic.css';

class Logistic extends React.Component {
  constructor(props) {
    super(props);
    this.state = { enabled: false };
    this.calcVolume = this.calcVolume.bind(this);
  }

  calcVolume() {
    var m3 = 4000;
    var vol = document.getElementById("id_input_logistic_volume");
    var height = document.getElementById("id_input_logistic_height").value;
    var lenght = document.getElementById("id_input_logistic_lenght").value;
    var width = document.getElementById("id_input_logistic_width").value;
    let result = (height * lenght * width) / m3; 
    vol.value = Math.round((result + Number.EPSILON) * 100) / 100;
  }

  render() {
    let {
      logisticHeightRequired,
      logisticLenghtRequired,
      logisticWidthRequired,
      logisticVolumeRequired,
      logisticFactorRequired,
      logisticWeightRequired,
    } = this.props.requiredLogistic;
    return (
      <div className="row">
        <div className="col-12">
          <Title
            title="LOGÍSTICA - MEDIDAS DEL EMBALAJE UNITARIO"
            description="Datos para el embalaje y/u otros factores"
          />
        </div>
        
        <div className="col-6">
          <InputTextAttribute
            measurement={true}
            title="Alto(cm)"
            name="logistic_height"
            id="id_input_logistic_height"
            type="number"
            min="0"
            max="1000000"
            calcVolume={this.calcVolume}
            flexline_name="analisisproducto25"
            calculatePercentOfInput={this.props.calculatePercentOfInput}
            required={logisticHeightRequired}
          />
        </div>
        <div className="col-6">
          <InputTextAttribute
            measurement={true}
            title="Profundidad(cm)"
            name="logistic_lenght"
            id="id_input_logistic_lenght"
            type="number"
            min="0"
            max="1000000"
            calculatePercentOfInput={this.props.calculatePercentOfInput}
            calcVolume={this.calcVolume}
            flexline_name="analisisproducto26"
            required={logisticLenghtRequired}
          />
        </div>
        <div className="col-6">
          <InputTextAttribute
            measurement={true}
            title="Ancho(cm)"
            name="logistic_width"
            id="id_input_logistic_width"
            type="number"
            min="0"
            max="1000000"
            calculatePercentOfInput={this.props.calculatePercentOfInput}
            calcVolume={this.calcVolume}
            flexline_name="analisisproducto24"
            required={logisticWidthRequired}
          />
        </div>
        <div className="col-6">
          <InputTextAttribute
            measurement={true}
            title="Volumen(m3)"
            name="logistic_volume"
            id="id_input_logistic_volume"
            flexline_name="volumen"
            calculatePercentOfInput={this.props.calculatePercentOfInput}
            type="number"
            min="0"
            max="1000000"
            required={logisticVolumeRequired}
            disabled
          />
        </div>
        <div className="col-6">
          <InputTextAttribute
            title="Factor"
            name="logistic_factor"
            id="id_input_logistic_factor"
            type="number"
            calculatePercentOfInput={this.props.calculatePercentOfInput}
            min="0"
            max="1000000"
            flexline_name="factoralt"
            required={logisticFactorRequired}
          />
        </div>
        <div className="col-6">
          <InputTextAttribute
            measurement={true}
            title="Peso(kg)"
            name="logistic_weight"
            id="id_input_logistic_size"
            type="number"
            calculatePercentOfInput={this.props.calculatePercentOfInput}
            min="0"
            max="1000000"
            flexline_name="peso"
            required={logisticWeightRequired}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  requiredLogistic: state.elementRequiredReducer.logistic,
});
const mapDispatchToProps = (dispach) => ({});
const wrapper = connect(mapStateToProps, mapDispatchToProps);
const component = wrapper(Logistic);

export default component;
